export function buildLeftHeader(cont) {
  var left = $(
    '<div class="navbar-start d-flex flex-row align-items-center"></div>'
  ).appendTo(cont);

  $(
    '<div class="toggle-btn"><span></span><span></span><span></span></div>'
  ).appendTo(left);
  $('.toggle-btn').on(click, function (evt) {
    evt.stopPropagation();
    $('body').toggleClass('show');
  });

  $('html').on(click, function (evt) {
    if (
      $(evt.target).closest('.sidebar ul li a[data-bs-toggle="collapse"]')
        .length == 0
    )
      $('body').removeClass('show');
  });

  var ul = $(
    '<ul id="headerLeftButtons" class="navbar-nav me-2 left--btns"></ul>'
  ).appendTo(left);
  tableButton(ul, {
    object: 'customer',
    objectSub: 'client',
    tip: parameter.customer.label,
  });
  tableButton(ul, {
    object: 'calendar',
    objectSub: user.calendarDefault ? user.calendarDefault : 'calendarPersonal',
    badge: {objectSub: 'calendarToday'},
    tip: parameter.calendar.label,
  });
  tableButton(ul, {
    object: 'mailStat',
    objectSub: 'inbox',
    badge: {objectSub: 'unseen'},
  });
  tableButton(ul, {
    object: 'followup',
    objectSub: 'taskToday',
    badge: {objectSub: 'taskToday'},
    tip: parameter.followup.label,
  });
  tableButton(ul, {object: 'document'});

  ul.children().each(function () {
    $(this).wrap('<li class="nav-item"></li>');
  });

  if (test && admin) {
    var debugInfo = getLocalFlag('debug-info');
    if (debugInfo) $(document.body).addClass('debug-info');
    buildFormLine(left, {
      field: 'checkbox',
      value: debugInfo,
      save: function (val) {
        if (val) $(document.body).addClass('debug-info');
        else $(document.body).removeClass('debug-info');
        toggleLocalFlag('debug-info');
      },
    });
  }

  $(
    '<i id="loading" class="icon fa-regular fa-spinner fa-spin fa-fw fa-lg d-none" aria-hidden="true"></i>'
  ).appendTo(left);
}
