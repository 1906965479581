import {flagButton} from './flagButton.mjs';

export function buildRightHeader(cont) {
  var right = $(
    '<div class="navbar-end d-flex flex-row align-items-center"></div>'
  ).appendTo(cont);
  buildGlobalSearch(right);

  var ul = $(
    '<ul id="headerRightButtons" class="navbar-nav me-2"></ul>'
  ).appendTo(right);

  var pm = {
    object: 'notification',
    badge: {objectSub: 'unseen'},
  };
  if (!user.admin)
    pm = Object.assign(pm, {
      popup: {title: 'title', content: 'message'},
      open: {system: 1},
    });
  tableButton(ul, pm);
  // if (user.admin) tableButton(ul, {object: 'guide', badge: {objectSub: 'unseen'}});

  $(
    '<a id="tabBtn" class="nav-link link-secondary"><i class="icon fa-regular fa-question-circle fa-lg fa-fw"></i></a>'
  )
    .appendTo(ul)
    .addTooltip(parameter.wiki.label)
    .on(click, function () {
      buildHelp();
    });

  tableButton(ul, {
    object: 'tracking',
    badge: {objectSub: 'trackingToday'},
  });

  if (test)
    $(
      '<a id="tabBtn" class="nav-link link-secondary"><i class="icon fa-regular fa-tv-retro fa-lg fa-fw"></i></a>'
    )
      .appendTo(ul)
      .addTooltip('Livesystem')
      .on(click, function () {
        window.open('https://' + client + '.' + domain);
      });

  $(
    '<a id="tabBtn" class="nav-link link-secondary"><i class="icon fa-regular fa-window-restore fa-lg fa-fw"></i></a>'
  )
    .appendTo(ul)
    .addTooltip('Zweiter Tab')
    .on(click, function () {
      window.open(document.location);
    });

  $(
    '<a id="refreshBtn" class="nav-link link-secondary"><i id="fullIcon" class="icon fa-regular fa-redo fa-lg fa-fw"></i></a>'
  )
    .appendTo(ul)
    .addTooltip('Aktualisieren [F5]')
    .on(click, function () {
      noLogoutInfo = true;
      reload();
    });

  flagButton(ul, 'chat', 'comment', 'Chat', function (value) {
    if (value) ajax({object: 'chat', callback: buildChat});
    else $('#chat').remove();
  });

  ul.children().each(function () {
    $(this).wrap('<li class="nav-item"></li>');
  });

  // profile
  var cont1 = $('<div class="dropdown ms-sm-2"></div>').appendTo(right);
  var cont2 = $(
    '<a class="d-flex link-secondary text-decoration-none dropdown-toggle" href="javascript:void(0)" data-bs-toggle="dropdown" aria-expanded="false">'
  ).appendTo(cont1);
  var cont3 = $('<div class="avatar avatar-md"></div>').appendTo(cont2);
  var src;
  if (user.avatar && user.avatar.length > 50) src = user.avatar;
  else if (user.pic) src = getObjectValue(data, ['document', user.pic, 'file']);
  if (src)
    $('<img class="img-fluid rounded-circle" src="' + src + '">').appendTo(
      cont3
    );
  else {
    if (user.acronym == 'upp') user.acronym = '';
    $(
      '<span class="avatar-title rounded-circle">' + user.acronym + '</span>'
    ).appendTo(cont3);
  }
  if (dropdowns) buildDropdown(cont1, dropdowns.profile, null, true);

  cont3.val = function (val) {
    cont3.empty();
    $('<img class="img-fluid rounded-circle" src="' + val + '">').appendTo(
      cont3
    );
  };
  register(cont3, 'user', user.id, 'avatar');

  // build setup bar
  if (
    user.setupUser &&
    store.setup < 100 &&
    !test &&
    getScreenWidth() >= 1920
  ) {
    var element = buildField(
      right,
      {object: 'store', row: store},
      'setup',
      fields.store.setup
    ).prependTo(right);
    element.addTooltip('Fortschritt Einrichtung');
    element.click(function () {
      ajax({
        type: 'detail',
        object: 'store',
        id: store.id,
        send: {update: true},
        noOverview: true,
      });
    });
  }

  if (test && user.admin) buildConfigSwitch(right).prependTo(right);
}
