import {buildLeftHeader} from './buildLeftHeader.mjs';
import {buildRightHeader} from './buildRightHeader.mjs';

// eslint-disable-next-line no-redeclare,no-unused-vars
function buildHeader() {
  var cont1 = $(
    '<header class="navbar navbar-expand bg-white"></header>'
  ).prependTo(document.body);
  var cont2 = $('<div class="container-fluid"></div>').appendTo(cont1);
  $(
    `<a href="/" id="logo" class="navbar-brand"><img src="${window.fileUrls.favicon}" width="32" height="32" class="img-fluid d-lg-none"><img src="${window.fileUrls.logo}" width="162" height="32" class="img-fluid d-none d-lg-block"></a>`
  ).appendTo(cont2);
  var cont3 = $(
    '<div class="collapse navbar-collapse justify-content-between"></div>'
  ).appendTo(cont2);

  buildLeftHeader(cont3);
  buildRightHeader(cont3);

  return cont1;
}

window.buildHeader = buildHeader;
