export function flagButton(cont, key, icon, tooltip, callback) {
  var value = getLocalFlag(key);
  var button = $(
    '<a id="' +
      key +
      'Btn" class="nav-link link-secondary"><i class="icon fa-regular fa-' +
      icon +
      ' fa-fw fa-lg"></i></a>'
  )
    .appendTo(cont)
    .addTooltip(tooltip)
    .on(click, function () {
      value = !value;
      setLocalFlag(key, value);
      button.toggleClass('border border-1 border-secondary');
      if (callback) callback(value);
    });
  if (value) {
    button.toggleClass('border border-1 border-secondary');
    if (callback) callback(value);
  }
}
